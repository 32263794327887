import React, { useState, useEffect } from 'react'
import { ethers } from "ethers";

import { useSendTransaction } from 'wagmi'
import { SpinnerCircular } from 'spinners-react';

const Transfer = () => {

    const [amount, setAmount] = useState('0')
    const [address, setAddress] = useState('')
    const [disabled, setDisabled] = useState(false)

    const TransferAmount = ethers.utils.parseUnits(amount, 18)

    const { data, isIdle, isError, isLoading, isSuccess, sendTransaction } = useSendTransaction({
      request: {
        to: address,
        value: TransferAmount,
      },
    })
    
    useEffect(() => {
        setDisabled(false)
        setAmount('0')
        setAddress('')
    }, [])

    return (
        <div>
        <br />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '10px' }}>Send Ethereum</div>
                <input
                    type="number"
                    step="100"
                    value={amount || ''}
                    onChange={(e) => {
                        if (e.currentTarget.value === "") {  } else { setAmount(e.currentTarget.value) }
                    }}
                    disabled={disabled}
                />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '10px' }}>Receipient:</div>
                <input
                    required="required"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.currentTarget.value)}
                    disabled={disabled}
                />
            </div>

            <button className="inp" disabled={isLoading} onClick={async () => await sendTransaction()}>
                Send
            </button>

            <br />

            <SpinnerCircular size={35} thickness={180} enabled={isLoading} color='rgba(255, 255, 255, 1)' />

            <div>
                {data && <div>Transaction: {JSON.stringify(data)}</div>}
                {isError && <div>Error sending transaction {JSON.stringify(isError)}</div>}
            </div>
        </div>

    )
}

export default Transfer