import React, { useEffect } from 'react'
import {
  useAccount,
  useConnect,
  useDisconnect 
} from 'wagmi'
import { BrowserView, MobileView } from 'react-device-detect'

import { toast } from "react-toastify";
import "styles/ReactToastify.css";

import { ReactComponent as Coinbase } from 'assets/coinbase.svg';
import { ReactComponent as Metamask } from 'assets/metamask.svg';

function LoginMod() {

    const { connect, connectors, error: connectError, isConnecting, pendingConnector } = useConnect()
    const { disconnect } = useDisconnect()
    const { address: accountAddress, connector, isReconnecting, isConnected, isDisconnected, status } = useAccount();

    const cbLink = "cbwallet://dapp?url=" + window.location.href;
    const mmLink = "https://metamask.app.link/dapp/" + window.location.href;

    useEffect(() => {

        if (connectError) {

            if (connectError.code === 4001) {
                toast("Error: " + connectError.code + connectError.message);
            }
            else if (connectError.code === -32002) {
                toast("Error: " + connectError.code + connectError.message);
            }
            else {
                toast("Error: " + connectError.code + connectError.message);
            }
        }
        
    }, [connectError])

    return (

            <div>

                <h3 className="mt0 mb20 txt-ct">CONNECT WALLET</h3>

                <div className="txt-ct">

                    <BrowserView>

                    <div className="flx-base">
                    
                        {connectors.map((connector) => (
                            <button
                                disabled={!connector.ready}
                                key={connector.id}
                                onClick={() => connect({ connector })}
                            >
                                {connector.name}
                                {!connector.ready && ' (unsupported)'}
                                {isConnecting &&
                                connector.id === pendingConnector?.id &&
                                ' (connecting)'}
                            </button>
                        ))}

                        <button onClick={() => disconnect()}>Disconnect</button>

                        {connectError && <div>{connectError.message}</div>}

                    </div>
                        
                    </BrowserView>

                    <MobileView>

                        { !window.ethereum &&
                            <>
                            <a href={cbLink}><Coinbase /></a>&nbsp;&nbsp;&nbsp;
                            <a href={mmLink}><Metamask /></a>
                            </>
                        }

                        { window.ethereum &&

                            <div className="flx-base">
                            {connectors.map((connector) => (
                                <button
                                className="inp"
                                    disabled={!connector.ready}
                                    key={connector.id}
                                    onClick={() => connect({ connector })}
                                >
                                    {connector.name}
                                    {!connector.ready && ' (unsupported)'}
                                </button>
                            ))}
                            </div>
                        }

                    </MobileView>

                    {connectError && <div>{connectError?.message ?? 'Failed to connect'}</div>}
                </div>
            </div>
    )
}

export default LoginMod