import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useAccount } from 'wagmi'
import MediaQuery from 'react-responsive'

const Nav = () => {
        
    const { ethereum } = window

    const { address, isConnected, isDisconnected, status } = useAccount();

    const location = useLocation();
    const [navbarOpen, setNavbarOpen] = useState(false)

    const isCurrentURL = (url) => {
        return location.pathname.toLowerCase() === url.toLowerCase();
    }

    const handleToggle = () => {
      setNavbarOpen(prev => !prev)
    }

    const owner = address || ethereum?.selectedAddress || null

    if (owner === null) {

        return (
        <div className="navigation app-nav container flx">
        <MediaQuery minWidth={749}>
            <ul className="navigation flx">
                { !isCurrentURL('/') ? 
                    <>
                        <li><Link to="/">login</Link></li>
                    </> 
                  : 
                    <>
                        <li><b>login</b></li>
                    </>  
                }
            </ul>
            </MediaQuery>
            <MediaQuery maxWidth={749}>

                <div className="header__btn">

                    <div id="navButton" onClick={handleToggle} className={` ${navbarOpen ? "open" : ""}`}>
                        <div className="bars link"><span></span><span></span><span></span><div className="other-bar"></div></div>
                    </div>

                </div>

                <div id="mobileMenu" className={`header__menu glass p0 ${navbarOpen ? "" : "slide"}`}>
                    <ul>
                        <li><Link onClick={handleToggle} className="blk p20" to="/">login</Link></li>
                    </ul>
                </div>
            </MediaQuery>
            
            <div className="logoTitle heading"><h1 className="nav"><Link to="/">TCA App</Link></h1></div>
                    
            <ul className="icons flx">
                { !isCurrentURL('/onboarding') ? 
                    <>
                        <li>o</li>
                    </> 
                  : 
                    <>
                        <li>o</li>
                    </>  
                }
            </ul>
        </div>

        )
    }

    if (owner !== null)

    return (
        <div className="navigation app-nav container flx">
        <MediaQuery minWidth={749}>
            <ul className="navigation flx">
                { !isCurrentURL('/') ? 
                    <>
                        <li><Link to="/">mint NFTs</Link></li>
                    </> 
                  : 
                    <>
                        <li><Link to="/"><b>mint NFTs</b></Link></li>
                    </>  
                }
                { !isCurrentURL('/dashboard') ? 
                    <>
                        <li><Link to="/dashboard">dashboard</Link></li>
                    </> 
                  : 
                    <>
                        <li><Link to="/dashboard"><b>dashboard</b></Link></li>
                    </>  
                }
                { !isCurrentURL('/token') ? 
                    <>
                        <li><Link to="/token">token</Link></li>
                    </> 
                  : 
                    <>
                        <li><Link to="/token"><b>token</b></Link></li>
                    </>  
                }
            </ul>

        </MediaQuery>

        <MediaQuery maxWidth={749}>

            <div className="header__btn">
                <div id="navButton" onClick={handleToggle} className={` ${navbarOpen ? "open" : ""}`}>
                    <div className="bars link"><span></span><span></span><span></span><div className="other-bar"></div></div>
                </div>
            </div>

            <div id="mobileMenu" className={`header__menu glass p0 ${navbarOpen ? "" : "slide"}`}>
                <ul>
                    <li><Link onClick={handleToggle} className="blk p20" to="/">mint NFTs</Link></li>
                    <li><Link onClick={handleToggle} className="blk p20" to="/dashboard">dashboard</Link></li>
                    <li><Link onClick={handleToggle} className="blk p20" to="/token">token</Link></li>
                </ul>
            </div>

        </MediaQuery>
            { !isCurrentURL('/') ? 
                <>
                    <div className="logoTitle heading"><h1 className="nav"><Link to="/">TCA App</Link></h1></div>
                </> 
              : 
                <>
                    <div className="logoTitle heading"><h1 className="nav" onClick={handleToggle}>TCA App</h1></div>
                </>  
            }

            <ul className="icons flx">
                { !isCurrentURL('/dashboard') ? 
                    <>
                        <li><Link to="/profile">Profile</Link></li>
                    </> 
                  : 
                    <>
                        <li><Link to="/profile"><b>Profile</b></Link></li>
                    </>  
                }
            </ul>
        </div>
    );
};

export default Nav;
