import Balance from 'utils/balance';
import SwitchNetwork from 'utils/switchNetwork';

const Profile = () => {

    return (

        <div className="app">
            
            <div className="card wide glass top appear min-h">
                <div className="ft-left">
                    <h1>Profile</h1>

                    <Balance />
                    <br />
                    <hr />
                    <br />
                    <SwitchNetwork />
                    

                </div> 
            </div>

            <div className="card glass top appear">
                <div className="ft-left">
                    <h2>about</h2>
                    <p>Just a demo with random sections doing web3 operations. All are customizable and exportable to any chain.</p>
                    <p>This is just a sneak peak, I can build a lot more. contact me for more details through <a href="https://linktr.ee/jonahlmadeya" target="_blank" rel="noopener noreferrer"><u>linktr.ee/jonahlmadeya</u></a></p>
                </div>
            </div>

            <div className="card glass top appear">
                <div>
                    <h2>links</h2>
                    <p>Some others web3 projects:</p>
                    <ul>
                        <li><a href="https://mishkablockchain.com/" target="_blank" rel="noopener noreferrer">- NFT gated shopify secret store</a></li>
                        <li><a href="https://neu-charitable.surge.sh/" target="_blank" rel="noopener noreferrer">- Neumorphism donate snippet</a></li>
                        <li><a href="https://metanauts.surge.sh/" target="_blank" rel="noopener noreferrer">- Metanauts (concept)</a></li>
                        <li><a href="https://thecoolagency.xyz/" target="_blank" rel="noopener noreferrer">- Solana powered ECommerce</a></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default Profile;
