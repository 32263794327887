import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { WagmiConfig, createClient, defaultChains, configureChains } from 'wagmi'

import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { providers } from 'ethers'

import Home from "Home";
import Dashboard from "Dashboard";
import NFTTools from "NFTtools";
import Exchange from "Exchange";
import Token from "Token";
import Landing from "Landing";
import Profile from "Profile";
import Page from "Page";
import Nav from "components/Nav";

const infuraId = process.env.REACT_APP_INFURA_ID
const alchemyId = process.env.REACT_APP_ALCHEMY_ID

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
    infuraProvider({ infuraId }),
    alchemyProvider({ alchemyId }),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://${chain.id}.infura.io`,
      }),
    }),
    publicProvider()
])

// Set up client
const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'TCA',
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
        qrcodeModalOptions: {
          mobileLinks: [
            "rainbow",
            "metamask",
            "argent",
            "trust"
          ]
        }
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
})

// Pass client to React Context Provider
function App() {

  return (
    <WagmiConfig client={client}>
        <div className="app-container">
        <div className="fix"></div>
            <Router>
            <Nav></Nav>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/NFTTools" element={<NFTTools />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/page" element={<Page />} />
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/exchange" element={<Exchange />} />
                    <Route path="/token" element={<Token />} />
                </Routes>
            </Router>
        </div>
    </WagmiConfig>
  )
}

export default App