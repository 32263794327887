import { useState } from 'react';
import { useAccount } from 'wagmi'
import ChainSelector from 'utils/ChainSelectorLive.jsx';
import NftCard from 'components/blocks/nftcardmainnet';
import {fetchNFTs} from 'utils/fetchNFTs';

const CheckNFTs = () => {

    const { address, isConnected, isDisconnected, status } = useAccount();
    const account = address || window.ethereum.selectedAddress
    const [owner, setOwner] = useState(account)
    const [contractAddress, setContractAddress] = useState("")
    const [NFTs, setNFTs] = useState("")
    const [chain, setBlockchain] = useState("Ethereum")

    return (
        <div>
            <header>
                <div>
                    <div>
                        <h1>NFTs</h1>
                        <p className="nbl">Browse all your NFT's</p><ChainSelector setBlockchain={setBlockchain} chain={chain} />
                    </div>
                    <div>
                        <input className="inp" value={owner} onChange={(e) => setOwner(e.target.value)} placeholder="Insert your wallet address"></input>
                        <input className="inp" value={contractAddress} onChange={(e) => setContractAddress(e.target.value)} placeholder="Insert NFT Contract address"></input>
                    </div>
                    <div>
                        <button className="inp" onClick={() => {fetchNFTs(owner, setNFTs, chain, contractAddress)}}>Search</button>
                    </div>
                </div>
            </header>
            <h3>Results:</h3>
            <section className="flx flexed">
                {
                    NFTs ? NFTs.map(NFT => {
                        
                        return (
                           <NftCard key={NFT.value.id + NFT.value.contractAddress} image={NFT.value.image} id={NFT.value.id} readable={NFT.value.numberIs} title={NFT.value.title} description={NFT.value.description} address={NFT.value.contractAddress} attributes={NFT.value.attributes} openseaURL={NFT.value.openseaURL} chain={NFT.value.chain}></NftCard>
                        )
                    }) : <div>No NFTs found</div>
                }
            </section>
        </div>
    )
}

export default CheckNFTs