import MyNFTs from 'utils/MyNFTs.jsx';
import CheckNFTs from 'components/CheckNFTs.jsx';

const NFTTools = (props) => {

    return (

        <div className="app">
            
            <div className="card wide glass top appear min-h">
                <div className="ft-left">
                
                    <CheckNFTs />

                </div> 
            </div>

        </div>
    )
}
export default NFTTools;
