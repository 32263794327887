import MyNFTs from 'utils/MyNFTs.jsx';
import BrowseNFTs from 'components/BrowseNFTs.jsx';
import WidgetContainer from 'components/OnRamper.jsx';

const Dashboard = (props) => {

    return (


        <div className="app">
            
            <div className="card wide glass top appear min-h">
                <div className="ft-left">
                    <h2>Dashboard</h2>
                    <hr />
                    <br />
                    <BrowseNFTs />
                </div> 
            </div>

            <div className="card glass top appear">
                <div className="ft-left">
                    <h2>fetch using injected only</h2>
                    <hr />
                    <br />
                    <MyNFTs />
                </div>
            </div>
            
            <div className="card glass top appear">
                <div className="ft-left">
                    <WidgetContainer />
                </div>
            </div>

        </div>




    )
}
export default Dashboard;
