import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import { useAccount } from 'wagmi'

import { BrowserView, MobileView } from 'react-device-detect';
import { ToastContainer } from "react-toastify";
import "styles/ReactToastify.css";

import Mint from 'components/nfts/NFTMinterAlpha.jsx';
import Mint2 from 'components/nfts/NFTMinterBeta.jsx';
import CustomMint from 'components/nfts/NFTMinterDIY.jsx';
import Hypnosia from 'components/Hypnosia';
import { SignMessage } from 'utils/signMessage'
import SwitchNetwork from 'utils/switchNetwork'
import WidgetContainer from 'components/OnRamper.jsx';
import LoginMod from "components/Login";

function Home() {

    const { address, isConnected, isDisconnected, status } = useAccount();

    const { ethereum } = window;
    const owner = address || ethereum?.selectedAddress || null;

    const connectWalletButton = () => {
        return (
            <LoginMod />
        )
    }

    return (
    <>
        <div className="app">

            <BrowserView className={`card wide glass top appear ${ isConnected ? "" : "centered"}`}>
                { isConnected ? <div className="ft-left"><Mint2 /></div> : connectWalletButton()}
            </BrowserView>

            { isConnected &&

                <>

                    <BrowserView className={`card wide glass top appear ${ isConnected ? "" : "centered"}`}>
                        {/*<SwitchNetwork />*/}
                        <Link to="/profile" className="button inp">click here to change network</Link>
                    </BrowserView>

                    <BrowserView className={`card glass top appear ${ isConnected ? "" : "centered"}`}>
                        <div className="ft-left">
                            {/*<WidgetContainer />*/}
                        </div>
                    </BrowserView>

                    <BrowserView className={`card glass top appear ${ isConnected ? "" : "m-w-80 centered"}`}>
                        <Mint />

                        <br />
                        <hr />
                        <br />

                        <div className="ft-left">
                            <h3>create your own</h3>
                            <br />
                            <CustomMint />
                        </div> 
                    </BrowserView>

                </>
            }
            
            { !isConnected &&
                <MobileView className="card wide glass top appear m-abs m-p-y login">
                    <div className="ft-left">
                        {connectWalletButton()}
                    </div> 
                </MobileView>
            }
            
            { isConnected &&
                <MobileView className="card wide glass top appear">
                    <div className="ft-left">
                        <Mint2 />
                    </div> 
                </MobileView>
            }
            
            { isConnected &&
                <MobileView className="card wide glass top appear">
                    <Mint />
                </MobileView>
            }
        
            { isConnected &&
                <MobileView className="card wide glass top appear">
                    <div className="ft-left">
                        <h3>create your own</h3>
                        <br />
                        <CustomMint />
                    </div> 
                </MobileView>
            }

            { isConnected && 
                <>
                    <Hypnosia />

                    <div className="card glass">
                        <h3>sign</h3>
                        <SignMessage />
                    </div>

                    <div className="card glass">
                        <div className="ft-left">
                            <h2>about</h2>
                            <hr />
                            <br />
                            <p>Just a demo with random sections doing web3 operations. All are customizable and exportable to any chain.</p>
                            <p>This is just a sneak peak, I can build a lot more. contact me for more details through <a href="https://linktr.ee/jonahlmadeya" target="_blank" rel="noopener noreferrer"><u>linktr.ee/jonahlmadeya</u></a></p>
                        </div>
                        <h2>links</h2>
                        <hr />
                        <br />
                        <p>Some others web3 projects:</p>
                        <ul>
                            <li><a href="https://mishkablockchain.com/" target="_blank" rel="noopener noreferrer">- NFT gated shopify secret store</a></li>
                            <li><a href="https://neu-charitable.surge.sh/" target="_blank" rel="noopener noreferrer">- Neumorphism donate snippet</a></li>
                            <li><a href="https://metanauts.surge.sh/" target="_blank" rel="noopener noreferrer">- Metanauts (concept)</a></li>
                            <li><a href="https://thecoolagency.xyz/" target="_blank" rel="noopener noreferrer">- Solana powered ECommerce</a></li>
                        </ul>
                    </div>
                </>
            }

        </div>
        <ToastContainer autoClose={false} draggableDirection="x" />
    </>
    )
}

export default Home;
