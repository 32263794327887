import { useState } from "react";
import { mintNFT } from "utils/mintCustomNFT.jsx";

const CustomMint = () => {

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");

  const onMintPressed = async () => {
      await mintNFT(url, name, description);
  };

  return (
    <div className="CustomMint">
      <form>
        <input
          type="text"
          placeholder="link to asset: https://.. or ipfs://...<hash>"
          onChange={(event) => setURL(event.target.value)}
        />
        <input
          type="text"
          placeholder="name of your NFT..."
          onChange={(event) => setName(event.target.value)}
        />
        <textarea placeholder="Description ..."
          onChange={(event) => setDescription(event.target.value)}
        ></textarea>
      </form>
      <button id="mintButton" className="inp" onClick={onMintPressed}>
        MINT NFT
      </button>
    </div>
  );
};

export default CustomMint;
