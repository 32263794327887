import React, { StrictMode } from "react";
import ReactDOM from "react-dom";

import "styles/fix.tca.css";
import "styles/theme.css";
import App from "App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

reportWebVitals();
