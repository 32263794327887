const getAddressNFTs = async (endpoint, owner, contractAddress, retryAttempt) => {
    if (retryAttempt === 5) {
        return;
    }
    if (owner) {
        let data;
        try {
            if (contractAddress) {
                data = await fetch(`${endpoint}/getNFTs?owner=${owner}&contractAddresses%5B%5D=${contractAddress}`).then(data => data.json())
            } else {
                data = await fetch(`${endpoint}/getNFTs?owner=${owner}`).then(data => data.json())
            }
        } catch (e) {
            getAddressNFTs(endpoint, owner, contractAddress, retryAttempt+1)
        }
        return data
    }
}

const getEndpoint = (chain) => {
    switch (chain) {
        case "Ethereum":
            return process.env.REACT_APP_ALCHEMY_ETHEREUM_ENDPOINT
        case "Polygon":
            return process.env.REACT_APP_ALCHEMY_POLYGON_ENDPOINT
        case "Mumbai":
            return process.env.REACT_APP_ALCHEMY_MUMBAI_ENDPOINT
        case "Goerli":
            return process.env.REACT_APP_ALCHEMY_GOERLI_ENDPOINT
        case "Rinkeby":
            return process.env.REACT_APP_ALCHEMY_RINKEBY_ENDPOINT
    }
}

const fetchNFTs = async (owner, setNFTs, chain, contractAddress) => {
    let endpoint = getEndpoint(chain)
    const data = await getAddressNFTs(endpoint, owner, contractAddress)
    if (data.ownedNfts.length) {
        const NFTs = await getNFTsMetadata(data.ownedNfts, endpoint, chain)
        console.log("NFTS metadata", NFTs)
        let fullfilledNFTs = NFTs.filter(NFT => NFT.status === "fulfilled")
        console.log("NFTS", fullfilledNFTs, chain)
        setNFTs(fullfilledNFTs)
    } else {
        setNFTs(null)
    }
}

const getNFTsMetadata = async (NFTS, endpoint, chain) => {
    const NFTsMetadata = await Promise.allSettled(NFTS.map(async (NFT) => {
        const metadata = await fetch(`${endpoint}/getNFTMetadata?contractAddress=${NFT.contract.address}&tokenId=${NFT.id.tokenId}`,).then(data => data.json())
        let image;
        let numberIs;
        let openseaURL;
        if (metadata.media[0].gateway.length) {
            image = metadata.media[0].gateway
        } else if (metadata.metadata.image_url.length) {
            let theMetaDataURL = metadata.metadata.image_url;
            image = theMetaDataURL.replace("ipfs://", "https://ipfs.io/ipfs/");
        } else {
            image = "https://via.placeholder.com/500"
        }
        if (chain === 'Ethereum') {
            numberIs = parseInt(NFT.id.tokenId, 16);
            console.log(numberIs)
            openseaURL = 'https://opensea.io/assets/ethereum/'
        } else {
            numberIs = NFT.id.tokenId;
            console.log(NFT.id.tokenId)
            openseaURL = 'https://opensea.io/assets/matic/'
        }
        return {
            id: NFT.id.tokenId,
            numberIs,
            contractAddress: NFT.contract.address,
            image,
            openseaURL,
            chain,
            title: metadata.metadata.name,
            description: metadata.metadata.description,
            attributes: metadata.metadata.attributes
        }
    }))
    return NFTsMetadata
}

export { fetchNFTs, getAddressNFTs }
