import OnramperWidget from "@onramper/widget";

export default function WidgetContainer() {
  const wallets = {
    BTC: { address: "btcAddr" },
    BNB: { address: "bnbAddress", memo: "cryptoTag" },
  };

  return (
    <div
      style={{
        width: "auto",
        height: "660px",
        boxShadow: "none",
        borderRadius: "10px",
        margin: "",
        color: "#ffffff!important",
        backgroundColor: "transparent"
      }}
    >
      <OnramperWidget
        API_KEY="pk_test_fsJ1wmhO4N0f9ZxROAbNyYDA9WsxN0SSgwOXsGrq8ws0"
        color="#ffffff"
        fontFamily="Helvetica"
        darkMode="true"
        defaultCrypto="ETH"
        defaultFiat="USD"
        defaultAmount="200"
        allow="accelerometer"
        supportSell="true"
        filters={{
          onlyCryptos: ["ETH"],
          onlyPaymentMethods: ["creditCard", "applePay", "googlePay"]
        }}
        redirectURL="https%3A%2F%2Flocalhost%3A3000%2Fdashboard"
      />
    </div>
  );
}