import { pinJSONToIPFS } from 'utils/pinata.jsx'
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require('contracts/abi/MckTCAP.json')
const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_TCA_CUSTOM;

export const mintNFT = async(url, name, description) => {

    if (url.trim() === "" || (name.trim() === "" || description.trim() === "")) { 
        return {
            success: false,
            status: "❗Please make sure all fields are completed before minting.",
        }
    }

    const metadata = {};
    metadata.name = name;
    metadata.image = url;
    metadata.description = description;

    const pinataResponse = await pinJSONToIPFS(metadata);
    if (!pinataResponse.success) {
        return {
            success: false,
            status: "Something went wrong while uploading your tokenURI.",
        }
    } 
    const tokenURI = pinataResponse.pinataUrl;  

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    const transactionParameters = {
        to: contractAddress, 
        from: window.ethereum.selectedAddress, 
        'data': window.contract.methods.mintNFT(window.ethereum.selectedAddress, tokenURI).encodeABI()
    };

    try {
        const txHash = await window.ethereum
            .request({
                method: 'eth_sendTransaction',
                params: [transactionParameters],
            });


        return {
            success: true,
            status: "Check out your transaction on Etherscan https://testnets.opensea.io/collection/mcktcao"
        }
    } catch (error) {
        return {
            success: false,
            status: "Something went wrong: " + error.message
        }
    }
}

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
        try {
            const addressArray = await window.ethereum.request({
            method: "eth_accounts",
        });
        return {
            address: addressArray[0],
            status: "Write a message in the text-field above.",
        };
        } catch (err) {
            return {
                address: "",
                status: " " + err.message,
            };
        }
    }
};
