const ChainSelector = ({setBlockchain, chain}) => {
    return (
        <div className="flx">
            <div>
                <input id="Rinkeby" onChange={(e) => setBlockchain(e.target.value)} className="pl10" type="radio" name='blockchain' value="Rinkeby" checked={chain === "Rinkeby"} />
                <label htmlFor="Rinkeby" className="ml5 mr10">Rinkeby</label>
            </div>
            <div>
                <input id="Goerli" onChange={(e) => setBlockchain(e.target.value)} className="pl10" type="radio" name='blockchain' value="Goerli" checked={chain === "Goerli"} />
                <label htmlFor="Goerli" className="ml5 mr10">Goerli</label>
            </div>
            <div>
                <input id="Eth" onChange={(e) => setBlockchain(e.target.value)} className="pl10" type="radio" name='blockchain' value="Ethereum" checked={chain === "Ethereum"} />
                <label htmlFor="Eth" className="ml5 mr10">Ethereum</label>
            </div>
            <div>
                <input id="Polygon" onChange={(e) => setBlockchain(e.target.value)} className="pl10" type="radio" name='blockchain' value="Polygon" checked={chain === "Polygon"} />
                <label htmlFor="Polygon" className="ml5 mr10">Polygon</label>
            </div>
            <div>
                <input id="Mumbai" onChange={(e) => setBlockchain(e.target.value)} className="pl10" type="radio" name='blockchain' value="Mumbai" checked={chain === "Mumbai"} />
                <label htmlFor="Mumbai" className="ml5 mr10">Mumbai</label>
            </div>
        </div>
    )
}

export default ChainSelector