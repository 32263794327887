import React, { useState, useEffect } from 'react'
import {
    ethers,
    Contract,
    utils,
    providers
} from "ethers";

import { useAccount, useNetwork } from 'wagmi'
import { toast } from "react-toastify";

import TokenFactory from "contracts/TCAToken.json";

const TokenTransfer = () => {

    const { address: accountAddress, isConnected, isDisconnected, status } = useAccount();
    const { chain } = useNetwork()

    const [amount, setAmount] = useState('0')
    const [address, setAddress] = useState('')
    const [disabled, setDisabled] = useState(false)

    let ercTokenAddress;
    let RPC_URL;

    if (chain.name === 'Ethereum') {
        ercTokenAddress = "";
        RPC_URL = process.env.REACT_APP_INFURA_MAINNET_RPC_URL
    } else if (chain.name === 'Goerli') {
        ercTokenAddress = process.env.REACT_APP_TCA_TOKEN_GOERLI
        RPC_URL = process.env.REACT_APP_INFURA_GOERLI_RPC_URL
    } else if (chain.name === 'Rinkeby') {
        ercTokenAddress = process.env.REACT_APP_TCA_TOKEN_RINKEBY
        RPC_URL = process.env.REACT_APP_INFURA_RINKEBY_RPC_URL
    } else if (chain.name === 'Kovan') {
        ercTokenAddress = process.env.REACT_APP_TCA_TOKEN_KOVAN
        RPC_URL = process.env.REACT_APP_INFURA_KOVAN_RPC_URL
    } else if (chain.name === 'Ropsten') {
        ercTokenAddress = process.env.REACT_APP_TCA_TOKEN_ROPSTEN
        RPC_URL = process.env.REACT_APP_INFURA_ROPSTEN_RPC_URL
    } else {
        ercTokenAddress = "";
        RPC_URL = ""
    }

    // token address | details
    const tokenSymbol = 'TCALPH';
    const tokenDecimals = 18;
    const tokenImage = 'https://etherscan.io/images/main/empty-token.png';

    // get address, either from wagmi or injected provider only login
    const account = accountAddress || window.ethereum.selectedAddress

    // provider for walletconnect | needs work
    const { JsonRpcProvider } = providers;

    const provider = new ethers.providers.Web3Provider(window.ethereum) || new JsonRpcProvider(RPC_URL)

    const signer = provider.getSigner();

    const TransferAmount = ethers.utils.parseUnits(amount, tokenDecimals)
    
    useEffect(() => {
        setDisabled(false)
        // setAmount(' ')
        setAddress(' ')
    }, [])

    function Execute() {

        if (!address || !amount)
            return;

        setDisabled(true);

        const ercInterface = new utils.Interface(TokenFactory);
        const contract = new Contract(ercTokenAddress, ercInterface, signer);

        contract.transfer(address, TransferAmount)
            .then(() => {
                setDisabled(false);
                toast("Transaction: " + amount + " sent to: " + address, "Success");
            })
            .catch((error) => {
                setDisabled(false);

                if (error.code === "4001") {
                    toast("User cancelled the transaction.", "Warning: 4001");
                } else if (error.error) {
                    toast(error.error.message, "Error: " + error.error.code);
                    console.log(error.error.message);
                } else {
                    console.log(error);
                    toast(error);
                }
            });
    }

    function ExecuteFirst() {
        Execute();
    }

    // add token to metamask
    const addToken = async () => {

        try {
          const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: ercTokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: tokenImage,
              },
            },
          });

          if (wasAdded) {
            console.log('Added to metamask');
          } else {
            console.log('Error');
          }
        } catch (error) {
          console.log(error);
        }
    }

    return (
        <div>
        <br />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '10px' }}>Send TCALPH</div>
                <input
                    type="number"
                    step="100"
                    value={amount || ''}
                    onChange={(e) => {
                        if (e.currentTarget.value === "") {  } else { setAmount(e.currentTarget.value) }
                    }}
                    disabled={disabled}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '10px' }}>Receipient:</div>
                <input
                    required="yes"
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.currentTarget.value)}
                    disabled={disabled}
                />
            </div>
            <button disabled={!account || disabled} onClick={() => { ExecuteFirst() }}>Send</button> 
            <button onClick={() => { addToken() }}>add token to wallet</button>
        </div>
    )
}
export default TokenTransfer