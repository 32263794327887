import Mint2 from 'components/nfts/NFTMinterBeta.jsx';

const Landing = () => {

    return (

        <div className="app">
            
            <div className="card wide glass top appear min-h">
                <div className="ft-left">
                    <h1 className="">Landing</h1>
                    <hr />
                    <Mint2 />
                </div> 
            </div>

            <div className="card glass top appear">
                <div className="ft-left">
                    <h2>section</h2>

                </div>
            </div>

            <div className="card glass top appear">
                <div className="">
                    <h2>section</h2>
                    
                </div>
            </div>

        </div>
    )
}
export default Landing
