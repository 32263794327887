import { useRef, useState, useMemo } from 'react'
import { verifyMessage } from 'ethers/lib/utils'
import { useSignMessage } from 'wagmi'

const Page = () => {

const previousMessage = useRef()
  const [message, setMessage] = useState('')
  const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage();

  const recoveredAddress = useMemo(() => {
    if (!data || !previousMessage.current) return undefined
    return verifyMessage(previousMessage.current, data)
  }, [data, previousMessage])

    return (

        <div className="app">
            
            <div className="card wide glass top appear min-h">
                <div className="ft-left">
                    <h1 className="">Page</h1>
                    <hr />

                    <form
                        onSubmit={(event) => {
                            event.preventDefault()
                            previousMessage.current = message
                            signMessage({ message })
                        }}
                    >
                        <label htmlFor="message">Enter a message to sign</label>
                        <textarea
                            id="message"
                            placeholder="The quick brown fox…"
                            onChange={(event) => setMessage(event.target.value)}
                        />
                        <button disabled={isLoading || !message.length}>
                            {isLoading ? 'Check Wallet' : 'Sign Message'}
                        </button>

                        {data && (
                            <div>
                                <div>Recovered Address: {recoveredAddress}</div>
                                <div>Signature: {data}</div>
                            </div>
                        )}
                        {isError && <div>{isError?.message ?? 'Error signing message'}</div>}
                    </form>

                </div> 
            </div>

            <div className="card glass top appear">
                <div className="ft-left">
                    <h2>test</h2>
                </div>
            </div>

            <div className="card glass top appear">
                <div className="">
                    <h2>section</h2>        
                </div>
            </div>
            
        </div>
    )
}
export default Page
