import { useBalance, useNetwork, useAccount } from 'wagmi'

export default function Balance() {

    const { address, isConnected, isDisconnected, status } = useAccount();
    const { data: networkData, chain } = useNetwork()
    const { data: balanceData, isError, isLoading } = useBalance({
        addressOrName: address
    })

    if (isLoading) return <div>Fetching balance…</div>
    if (isError) return <div>Error fetching balance</div>

    return (
        <div>
            {balanceData?.formatted} {balanceData?.symbol}
        </div>
    )

}

