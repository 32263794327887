import React, { useState } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import "styles/ReactToastify.css";
import { 
    BrowserView,
    MobileView,
    isChrome,
    isSafari,
    isFirefox,
    isEdge,
    isIE,
    isOpera
} from "react-device-detect";
import { SpinnerCircular } from 'spinners-react';

import contract from "contracts/TCAxEVM.json";
const contractAddress = "0xcD4Fcba8C4f67A9bbC934d96a8fCbbFF6c89cD05";
const abi = contract;

function Mint() {

    function validateForm() {
        if (finalCount < 1 || finalCount > 3) {
            alert('Change quantity');
        }
        else {
            alert('OK');
            MintNFT()
        }
    }

    const [finalCount, setFinalAmount] = useState('0');

    let error;
    const hasCapabilities = window.ethereum;
    const [isHover, setIsHover] = useState(false);
    const [disable, setDisable] = useState(false)
    const [status, setStatus] = useState("mint NFT");
    const [statusMessage, setStatusMessage] = useState("");
    const [statusText, setStatusText] = useState("mint your NFT and have access to the surprise unlockable content.");
    const [statusSpin, setStatusSpin] = useState(false);

    const MintNFT = async () => {
        try {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                console.log("##### ____________\n##### ABI / SIGNER :\n##### ____________");

                console.log(abi)
                console.log(signer)

                // STEP 1 - Confirm in Wallet

                setDisable(true)
                setStatus("confirm in wallet...");
                setStatusText("Please confirm transaction in your wallet.");
                console.log("Going to pop wallet now to pay gas...")

                const count = finalCount;
                const price = 0.02;

                const totalPrice = price * count;
                const stringPrice = totalPrice.toString();

                const setArgs = {
                    value: ethers.utils.parseEther(stringPrice),
                    gasLimit: 221649,
                };

                // STEP 2 - Minting

                let nftTxn = await nftContract.mintPublicSale(count, setArgs);

                console.log("##### ____________\n##### nftTxn :\n##### ____________");
                console.log(nftTxn)

                console.log("Mining...please wait.")
                setStatus("minting...");
                setStatusText("Waiting for the transaction to complete.");
                setStatusSpin(true);

                // STEP 3 - Mint Results
                
                let tx = await nftTxn.wait();

                console.log("##### ____________\n##### TX :\n##### ____________");
                console.log(tx);
                let event = tx.events[0]
                console.log("##### ____________\n##### TX Events 0 :\n##### ____________");
                console.log(event)
                let value = event.args[2]
                console.log("##### ____________\n##### TX Events Args 2 :\n##### ____________");
                console.log(value)
                let tokenId = value.toNumber()
                console.log("##### ____________\n##### TX Events 2 - Value Number :\n##### ____________");
                console.log(tokenId)

                console.log(`Mined, see transaction: https://goerli.etherscan.io/tx/${nftTxn.hash}`);
                setStatusMessage("https://testnets.opensea.io/assets/" + contractAddress+"/"+ tokenId.toNumber());

                // STEP 4 - 

                setStatus("Last step...");
                setStatusText("Wait for it to reflect on OpenSea...");

                nftContract.on("MintPublicSale", (from, tokenCount) => {

                    // STEP 5

                    console.log("##### ____________\n##### tokenCount :\n##### ____________");
                    console.log(tokenCount)
                    console.log(tokenCount.toNumber())
                    setStatusMessage("https://testnets.opensea.io/assets/" + contractAddress+"/"+ tokenCount.toNumber());
                    setStatusText("<u>You minted" + tokenCount.toNumber() + "NFT's. Click here to see your new NFT on OpenSea!</u>");
                    setStatusSpin(false);
                    setDisable(false);
                    setStatus("Mint another");
                });

            } else {

                console.log("Ethereum object doesn't exist!");
                await toast("Error: " + error.message);
                setStatusText("Error.");
                setStatus("MINT NFT");
                setDisable(false);
                setStatusSpin(false);

            }
        } catch (error) {

            console.log(error)
            await toast("Error: " + error.message);
            setStatusText("Hello.");
            setStatus("MINT NFT");
            setDisable(false);
            setStatusSpin(false);
        }
    }

    const mintNftButton = () => {

        return (
            <div>

                <h6>MINT AN NFT (available only on goerli network)</h6>

                <h2 className="appear mb10">TBD NFT ()</h2>

                <div className="mint-result mb20">
                    <a href={statusMessage} target="_blank" rel="noreferrer noopener">{statusText}</a>
                    <SpinnerCircular size={35} thickness={180} enabled={statusSpin} color='rgba(255, 255, 255, 1)' />
                </div>

                <form   onSubmit={(e) => {
                            e.preventDefault();
                            validateForm();
                        }}>

                        <input
                            className="input"
                            type="number"
                            required={true}
                            step="1"
                            placeholder="Amount.."
                            value={finalCount}
                            onChange={(e) => {
                                if (parseInt(finalCount) >= 1) {
                                    setFinalAmount(finalCount)
                                }; setFinalAmount(e.currentTarget.value)} }
                            min="1"
                            max="3"
                            disabled={disable} />

                    <button 
                        disabled={disable}
                        className={`button inp mint-nft-button glass-input ${isHover ? "hover" : ""}`}
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}>

                        <span>{status}</span>

                    </button>

                </form>
            </div>
        )
    }

    return (
        <div>
            <BrowserView className={` ${ `some` ? "" : "centered"}`}>
                { hasCapabilities !== undefined && isChrome === true && 
                    <div className="ft-left">
                        {mintNftButton()}
                        <small>chrome | web3 enabled</small>
                    </div>
                }
                { hasCapabilities !== undefined && isFirefox === true && 
                    <div className="ft-left">
                        {mintNftButton()}
                        <small>firefox  | web3 enabled</small>
                    </div>
                }
                { hasCapabilities !== undefined && isEdge === true && 
                    <div className="ft-left">
                        {mintNftButton()}
                        <small>edge | web3 enabled</small>
                    </div>
                }
                { hasCapabilities !== undefined && isOpera === true && 
                    <div className="ft-left">
                        {mintNftButton()}
                        <small>opera | web3 enabled</small>
                    </div>
                }
                { hasCapabilities === undefined && isSafari === true && 
                    <div>
                        <h3>Use a different browser</h3>
                        <small>safari | no support</small>
                    </div>
                }
                { hasCapabilities === undefined && isIE === true && 
                    <div>
                        <h3>Use a different browser</h3>
                        <small>IE | no support</small>
                    </div>
                }
                { hasCapabilities === undefined && isSafari === false && 
                    <div>
                        <h3>Please install a wallet to connect</h3>
                        <small>no support</small>
                    </div>
                }
            </BrowserView>
            <MobileView>
                <div className="ft-left">
                    {mintNftButton()}
                </div>
            </MobileView>
        </div>
    )
}

export default Mint;
