import { useBalance, useAccount, useNetwork } from 'wagmi'

export default function TokenBalance() {

    const { address, isConnected, isDisconnected, status } = useAccount();
    const { chain } = useNetwork()

    let tokenAddress;

    if (chain.name === 'Ethereum') {
        tokenAddress = "";
    } else if (chain.name === 'Goerli') {
        tokenAddress = process.env.REACT_APP_TCA_TOKEN_GOERLI
    } else if (chain.name === 'Rinkeby') {
        tokenAddress = process.env.REACT_APP_TCA_TOKEN_RINKEBY
    } else if (chain.name === 'Kovan') {
        tokenAddress = process.env.REACT_APP_TCA_TOKEN_KOVAN
    } else if (chain.name === 'Ropsten') {
        tokenAddress = process.env.REACT_APP_TCA_TOKEN_ROPSTEN
    } else {
        tokenAddress = "";
    }

    const { data, isError, isLoading } = useBalance({
        addressOrName: address,
        formatUnits: '18',
        token: tokenAddress,
    })

    if (isLoading) return <div>Fetching balance…</div>
    if (isError) return <div>Error fetching balance</div>

    return (
        <>
            <div>
                {data?.formatted} {data?.symbol}
            </div>
        </>
    )

}

