import { useNetwork, useSwitchNetwork } from 'wagmi'

export default function SwitchNetwork() {

    const { chain } = useNetwork()
    const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();

  return (
    <div>
      <button className="connected">
        connected to {chain?.name ?? chain?.id}{' '}
        {chain?.unsupported && '(unsupported)'}
      </button>

      {chains.map((x) => (
        <button
          disabled={!switchNetwork || x.id === chain?.id}
          key={x.id}
          onClick={() => switchNetwork?.(x.id)}
        >
          {x.name}
          {isLoading && pendingChainId === x.id && ' (switching)'}
        </button>
      ))}

      {error && <div>{error?.message}</div>}
    </div>
  )
}