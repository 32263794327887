import { ReactComponent as Etherscan } from 'assets/etherscan.svg';

const NftCard = ({ image, id, title, address, description, attributes }) => {
    return (
        <div className="glass nftcard">
            <div className="wrap-nft">
                <img key={id} src={image} alt={description? description.slice(0, 60) : "No Description"}></img>
            </div>
            <div>
                <div>
                    <div>
                        <h3 className="NFTcardTitle">{title}</h3>
                        <span>{`${id.slice(0, 4)}...${id.slice(id.length - 4)}`} &nbsp;&nbsp;
                        <a href={"https://rinkeby.etherscan.io/token/" + address} target="_blank" alt="etherscan link" className="nbl" rel="noreferrer noopener"><Etherscan /></a></span><br />
                        <a target="_blank" href={`https://etherscan.io/token/${address}`} rel="noreferrer noopener">{`${address.slice(0, 4)}...${address.slice(address.length - 4)}`}</a>
                        <div onClick={() => navigator.clipboard.writeText(address)} className="nbl">&nbsp;| copy</div>
                    </div>
                </div>
                <p>{description? description.slice(0, 60) : "No Description"}</p>
            </div>

            {/* show attributes of nft */}
            {/*<div>

                {attributes?.length > 0 && attributes.map(attribute => {
                    return (
                        <div key={attribute.value}>
                            <p>{attribute.trait_type}:</p>
                            <p>{attribute.value}</p>
                        </div>
                    )
                })}
            </div>*/}

        </div>
    )
}

export default NftCard
