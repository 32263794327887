// fix provider to use Wagmi, or at least know we can use it.

import { useState } from 'react'
import { ethers, providers } from 'ethers'

import { useAccount, useNetwork } from 'wagmi'
import axios from 'axios'
import "styles/ReactToastify.css";

import { ReactComponent as Opensea } from 'assets/opensea.svg';
import { ReactComponent as Rarible } from 'assets/rarible.svg';
import { ReactComponent as Etherscan } from 'assets/etherscan.svg';

import contract from "contracts/MemesTCA.json";

const abi = contract;

const { JsonRpcProvider } = providers;

const GetMyNFTs = () => {

    const { address, isConnected, isDisconnected, status } = useAccount();
    const { chain } = useNetwork()

    const [ownedNFTs, setOwnedNFTs] = useState("");
    const [ownedNFTsURL, setOwnedNFTsURL] = useState("");
    // const [network, setNetwork] = useState('')
    const [mintedNFT, setMintedNFT] = useState(null)
    const [mintedName, setMintedName] = useState(null)
    const [mintedDescription, setMintedDescription] = useState(null)

    let contractAddress;
    let RPC_URL;

    if (chain.name === 'Ethereum') {
        contractAddress = "";
        RPC_URL = process.env.REACT_APP_INFURA_MAINNET_RPC_URL
        // setNetwork('ethereum')
    } else if (chain.name === 'Goerli') {
        contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_TCA_MEMES_GOERLI
        RPC_URL = process.env.REACT_APP_INFURA_GOERLI_RPC_URL
        // setNetwork('goerli')
    } else if (chain.name === 'Rinkeby') {
        contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_TCA_MEMES_RINKEBY
        RPC_URL = process.env.REACT_APP_INFURA_RINKEBY_RPC_URL
        // setNetwork('rinkeby')
    } else if (chain.name === 'Kovan') {
        contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_TCA_MEMES_KOVAN
        RPC_URL = process.env.REACT_APP_INFURA_KOVAN_RPC_URL
        // setNetwork('kovan')
    } else if (chain.name === 'Ropsten') {
        contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS_TCA_MEMES_ROPSTEN
        RPC_URL = process.env.REACT_APP_INFURA_ROPSTEN_RPC_URL
        // setNetwork('ropsten')
    } else {
        contractAddress = "";
        RPC_URL = ""
        // setNetwork('local')
    }

    const account = address

    const provider = new JsonRpcProvider(RPC_URL)

    const getMyNFTs = async () => {

        try {

            if (provider) {

                const contract = new ethers.Contract(contractAddress, abi, provider)

                const baseURI = await contract.baseTokenURI()
                const ownedToken = await contract.tokensOfOwner(account)

                let tokenIDs = []
                let tokenHTTPs = []

                for (let i = 0; i < ownedToken.length; i++) {

                    let oneToken = ownedToken[i];
                    let tokenID = oneToken.toNumber()
                    tokenIDs.push(tokenID)

                    let fullURI = baseURI + tokenID

                    let tokenHTTP = fullURI.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/")
                    tokenHTTPs.push(tokenHTTP)
                }

                setOwnedNFTs(tokenIDs);
                setOwnedNFTsURL(tokenHTTPs);

                const lastNFT = tokenHTTPs.length -1         

                let data = await axios.get(tokenHTTPs[lastNFT], {timeout: 3000})
                let meta = data.data
                let tokenImage = meta.image.replace("ipfs://", "https://gateway.pinata.cloud/ipfs/")

                setMintedNFT(tokenImage)
                setMintedName(meta.name)
                setMintedDescription(meta.description)

            }

        } catch(error) {
            console.log(error)
        }
    }

    return (

        <div>

            <div className="flx-base">

                <div className="column">

                    <h4 className="mb0">
                        My NFT's from Memes90
                    </h4>
                    <br />
                    <button onClick={() => getMyNFTs()}>get</button>
                    <br />
                    <br />
                    <div>
                        <p>You own {ownedNFTs.length} of the NFT Collection.</p>
                    </div>

                </div>

                <div className="column glass">

                    <h3>Latest:</h3>
                    <div className="results-">
                        <img
                            src={mintedNFT}
                            alt="last nft"
                            className="large"
                        />
                        <div className="details">
                            <h2>{mintedName}</h2>
                            <p>{mintedDescription}</p>
                        </div>
                    </div>

                </div>


            </div>

            <div>


                <h3>Collected NFT's:</h3>
                <div className="flx wrp">

                {
                    ownedNFTs ? ownedNFTs.map((ownedNFT, index) => {

                        return (
                            <>
                            <code key={ownedNFT+contractAddress} className="animated fadeInUp"><a href={ownedNFTsURL[index]} target="_blank" rel="noreferrer noopener">NFT ID: {ownedNFT} </a>
                                {/*<a href={"https://testnets.opensea.io/assets/" + network + "/" + contractAddress + "/" + ownedNFT} target="_blank" alt="OpenSea link" rel="noreferrer noopener"><Opensea /> opensea</a>&nbsp;&nbsp;
                                <a href={"https://" + network + ".rarible.com/token/" + contractAddress + "/" + ownedNFT} target="_blank" alt="Rarible link" rel="noreferrer noopener"><Rarible /> rarible</a>&nbsp;&nbsp;
                                <a href={"https://" + network + ".etherscan.io/token/" + contractAddress + "/" + ownedNFT} target="_blank" alt="etherscan link" rel="noreferrer noopener"><Etherscan /> etherscan</a>*/}
                            </code>
                            </>
                        )
                    }) : <div>No NFTs found</div>
                }

                </div>

            </div>

        </div>

    )
}

export default GetMyNFTs
