import { useState } from 'react';
import { useAccount } from 'wagmi'
import ChainSelector from 'utils/ChainSelector.jsx';
import NftCard from 'components/blocks/nftcard';
import {fetchNFTs} from 'utils/fetchNFTs';

const BrowseNFTs = () => {

    const { address, isConnected, isDisconnected, status } = useAccount();
    const account = address || window.ethereum.selectedAddress
    const owner = account
    const [contractAddress, setContractAddress] = useState("")
    const [NFTs, setNFTs] = useState("")
    const [chain, setBlockchain] = useState("Ethereum")

    return (
        <div>
            <header>
                <div>
                    <div>
                        <h3 className="nbl">Browse all your NFT's</h3>
                        <div className="flx"><ChainSelector setBlockchain={setBlockchain} chain={chain} /> <button className="inp" onClick={() => {fetchNFTs(account, setNFTs, chain, contractAddress)}}>Search</button></div>
                    </div>
                </div>
            </header>
            <h3>Results:</h3>
            <section className="flx flexed">
                {
                    NFTs ? NFTs.map(NFT => {
                        
                        return (
                           <NftCard key={NFT.value.id + NFT.value.contractAddress} image={NFT.value.image} id={NFT.value.id} title={NFT.value.title} description={NFT.value.description} address={NFT.value.contractAddress} attributes={NFT.value.attributes}></NftCard>
                        )
                    }) : <div>No NFTs found</div>
                }
            </section>
        </div>
    )
}

export default BrowseNFTs