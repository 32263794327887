import TokenTransfer from 'components/TokenTransfer.jsx';
import Transfer from 'components/Transfer.jsx';
import SwitchNetwork from 'utils/switchNetwork';

import Balance from 'utils/balance'
import TokenBalance from 'utils/tokenBalance'
import { ToastContainer } from "react-toastify";

const Token = () => {

    return (
        <div>

            <div className="app">

                <div className="card wide glass top appear">
                    <div>

                        <h2>Balances</h2>
                        <hr />
                        <br />
                        <TokenBalance />
                        <Balance />
                        <br />
                        <SwitchNetwork />

                    </div>
                </div>
                
                <div className="card glass top appear">
                    <div className="ft-left">

                        <h2>Transfer ERC20</h2>
                        <hr />
                        <TokenTransfer />

                    </div> 
                </div>

                <div className="card glass top appear">
                    <div className="ft-left">

                        <h2>Transfer Native</h2>
                        <hr />
                        <Transfer />

                    </div>
                </div>

            </div>

            <ToastContainer autoClose={false} draggableDirection="x" />

        </div>
    )
}
export default Token
