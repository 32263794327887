import { useRef, useState, useMemo } from 'react'
import { verifyMessage } from 'ethers/lib/utils'
import { useSignMessage } from 'wagmi'

export const SignMessage = () => {
  const previousMessage = useRef()
  const [message, setMessage] = useState('')
  const { data, error, isLoading, signMessage } = useSignMessage()

  const recoveredAddress = useMemo(() => {
    if (!data || !previousMessage.current) return undefined
    return verifyMessage(previousMessage.current, data)
  }, [data, previousMessage])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        previousMessage.current = message
        signMessage({ message })
      }}
    >
      <label htmlFor="message">Enter a message to sign</label>
      <textarea
        className="mt10"
        id="message"
        placeholder="test message be..."
        onChange={(event) => setMessage(event.target.value)}
      />
      <button disabled={isLoading || !message.length}>
        {isLoading ? 'Check Wallet' : 'Sign Message'}
      </button>

      {data && (
        <div>
          <div>Recovered Address: {recoveredAddress}</div>
          <div>Signature: {data}</div>
        </div>
      )}
      {error && <div>{error?.message ?? 'Error signing message'}</div>}
    </form>
  )
}