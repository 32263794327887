import React, { useState } from "react";

function Hypnosia() {

    const [isRotating, setIsRotating] = useState(false);

    return (
        <div className="card glass wide card-special">
            <h3>NFT HYPNOSIA</h3>
            <div className="container-special"
                onMouseEnter={() => setIsRotating(true)}
                onMouseLeave={() => setIsRotating(false)}>
              <div className={`loader ${isRotating ? "animate" : ""}`}></div>
              <div className="rotator">
                <div className="circle"></div>
              </div>
            </div>
        </div>
    )
}

export default Hypnosia;
